// This component is used to render HTML that is generated by the PHP app or Strapi. Styling
// is broken up into `shared`, `troubleshooting`, and `commerce` variables to control what
// is included, and where via the styleMap.

/**
 * Even though it is not directly used, we need to import ComponentWithAs here, due to a TypeScript
 * issue that was _just_ fixed. Once we upgrade to TS 5.5, we should be able to remove this import.
 * See commit message for more details.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, BoxProps, SystemStyleObject, forwardRef, ComponentWithAs } from '@chakra-ui/react';
import { useMemo } from 'react';

export type PrerenderedHTMLProps = BoxProps & {
   html: string;
};

export const PrerenderedHTML = forwardRef<PrerenderedHTMLProps, 'div'>(function PrerenderedHTML(
   { html, sx, className, ...others },
   ref
) {
   const actualSx = useMemo(() => ({ ...prerenderedDefaultStyles, ...sx }), [sx]);

   const actualHTML = useMemo(() => ({ __html: html }), [html]);

   return (
      <Box
         ref={ref}
         className={className ? `prerendered ${className}` : 'prerendered'}
         sx={actualSx}
         dangerouslySetInnerHTML={actualHTML}
         {...others}
      />
   );
});

export const prerenderedDefaultStyles: SystemStyleObject = {
   a: {
      color: 'brand.500',
      transition: 'color 300ms',

      '&:hover': {
         color: 'brand.600',
         textDecoration: 'underline',
      },
   },

   blockquote: {
      marginTop: 6,
      borderLeftWidth: '4px',
      borderStyle: 'solid',
      paddingInline: '1em',
   },

   h3: {
      fontSize: 'xl',
      lineHeight: '1.2',
   },

   'h3, h4': {
      fontWeight: 600,
   },

   'h4, h5': {
      fontSize: 'md',
      lineHeight: '1.25',
   },

   pre: {
      marginTop: '1em',
      paddingInline: 1,
      maxWidth: '100%',
      overflow: 'auto',
      whiteSpace: 'break-spaces',
   },

   th: {
      textAlign: 'left',
   },

   '.fa-svg-icon': {
      svg: {
         width: '1em',
         height: '1em',
         display: 'inline-block',

         path: {
            fill: 'currentColor',
         },
      },

      '&.xs svg': {
         width: '0.5em',
         height: '0.5em',
      },

      '&.sm svg': {
         width: '0.75em',
         height: '0.75em',
      },
   },
   'ul, ol': {
      my: 3,
      paddingInlineStart: 1,
      listStylePosition: 'inside',
   },
   p: {
      mb: 3,
      _last: {
         mb: 0,
      },
   },
};
