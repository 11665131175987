/**
 * Even though it is not directly used, we need to import ComponentWithAs here, due to a TypeScript
 * issue that was _just_ fixed. Once we upgrade to TS 5.5, we should be able to remove this import.
 * See commit message for more details.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SystemStyleObject, forwardRef, ComponentWithAs } from '@chakra-ui/react';
import 'lite-youtube-embed/src/lite-yt-embed.css';
import { useEffect } from 'react';
import { PrerenderedHTML, type PrerenderedHTMLProps } from './PrerenderedHTML';

export const WikiPrerenderedHTML = forwardRef<PrerenderedHTMLProps, 'div'>(
   function WikiPrerenderedHTML({ html, sx, ...others }, ref) {
      useEffect(() => {
         /** @ts-expect-error - lite-youtube-embed has no types package and we don't need one */
         // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Promises must be awaited, end with...
         import('lite-youtube-embed');
      }, []);

      return <PrerenderedHTML ref={ref} sx={{ ...wikiStyles, ...sx }} html={html} {...others} />;
   }
);

const constrainStandardWidth = '282px'; // pulled from PHP app for legacy image sizing

const wikiStyles: SystemStyleObject = {
   '&': {
      gap: '1em',
   },

   '.clearer': {
      clear: 'both',
      height: '0',
      padding: '0',
      margin: '0',
      lineHeight: '0',
      fontSize: '0',
   },

   // handle the first-child header, which gets .clearer appended
   '.clearer:not(:first-child) + .headerContainer': {
      marginTop: { base: 4, sm: 6 },
   },

   '.headerContainer': {
      display: 'flex',
      alignItems: 'baseline',

      '&:hover .selfLink': {
         opacity: '1',
      },

      '.selfLink': {
         color: 'gray.500',
         order: '2', // swap the legacy order with flexbox
         opacity: '0',
         paddingLeft: 2,
         transition: 'opacity ease-in-out',
         transitionDuration: 'fast',
      },
   },

   blockquote: {
      borderLeftWidth: '4px',
      marginTop: '1em',
      paddingLeft: '1em',

      '&.featured': {
         borderWidth: '1px',
         borderRadius: 'md',
         padding: 2,
         color: 'orange.500',
         bgColor: 'white',
      },

      '& > p:not(:first-child)': {
         marginTop: '1em',
      },
   },

   'code, pre': {
      backgroundColor: 'gray.300',
      borderRadius: 'md',
      color: 'coolGray.600',
      borderStyle: 'none',
   },

   p: {
      lineHeight: '1.38',
      alignSelf: 'stretch',

      '&:not(:first-child)': {
         marginTop: '1em',
      },
   },

   // use 1em as a standard spacing for lists
   'ul, ol': {
      marginTop: '1em',
      marginInlineStart: '1em',
      paddingInlineStart: 'calc(1em + 2px)',

      li: {
         _notFirst: {
            marginTop: '1em',
         },
      },
   },

   '.table-container': {
      position: 'relative',
      width: 'fit-content',
      marginTop: 4,
   },

   '.table-overflow': {
      overflowX: 'auto',
   },

   table: {
      maxWidth: '100%',
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
      borderSpacing: 0,

      th: {
         // The default text align is centered for th elements, so hard-code left.
         textAlign: 'left',
      },
   },

   '.table': {
      width: '100%',
      marginBottom: '18px',
      // Cells
      'th, td': {
         padding: '8px',
         lineHeight: '18px',
         textAlign: 'left',
         verticalAlign: 'top',
         borderTopWidth: '1px',
      },
      th: {
         fontWeight: 'bold',
      },
      // Bottom align for column headings
      'thead th': {
         verticalAlign: 'bottom',
      },

      caption: {
         textAlign: 'start',
         fontSize: '14px',
      },

      // Remove top border from thead by default
      'caption + thead tr:first-child th,\
      caption + thead tr:first-child td,\
      colgroup + thead tr:first-child th,\
      colgroup + thead tr:first-child td,\
      thead:first-child tr:first-child th,\
      thead:first-child tr:first-child td': {
         borderTop: 0,
      },
      // Account for multiple tbody instances
      'tbody + tbody': {
         borderTopWidth: '2px',
      },

      'th.center, td.center': {
         textAlign: 'center',
      },

      'td.align-right, th.align-right': {
         textAlign: 'right',
      },

      'td.number, th.number': {
         textAlign: 'right',
      },
   },

   '.table-condensed': {
      'th, td': {
         padding: 3,
      },
   },

   '.table-bordered': {
      borderWidth: '1px',
      borderColor: 'gray.300',
      borderCollapse: 'separate', // Done so we can round those corners!
      borderRadius: '8px',

      '&.table-bordered-square': {
         borderRadius: 0,
      },
      'th:not(:first-child), td:not(:first-child)': {
         borderLeftWidth: '1px',
         borderColor: 'gray.300',
      },
      // Prevent a double border
      'caption + thead tr:first-child th,\
      caption + tbody tr:first-child th,\
      caption + tbody tr:first-child td,\
      colgroup + thead tr:first-child th,\
      colgroup + tbody tr:first-child th,\
      colgroup + tbody tr:first-child td,\
      thead:first-child tr:first-child th,\
      tbody:first-child tr:first-child th,\
      tbody:first-child tr:first-child td': {
         borderTop: 0,
      },
      // For first th or td in the first row in the first thead or tbody
      'thead:first-child tr:first-child th:first-child,\
      tbody:first-child tr:first-child td:first-child': {
         WebkitBorderTopLeftRadius: '4px',
         borderTopLeftRadius: '4px',
         MozBorderRadiusTopLeft: '4px',
      },
      'thead:first-child tr:first-child th:last-child,\
      tbody:first-child tr:first-child td:last-child': {
         WebkitBorderTopRightRadius: '4px',
         borderTopRightRadius: '4px',
         MozBorderRadiusTopRight: '4px',
      },
      // For first th or td in the first row in the first thead or tbody
      'thead:last-child tr:last-child th:first-child,\
      tbody:last-child tr:last-child td:first-child,\
      tfoot:last-child tr:last-child td:first-child': {
         borderRadius: '0 0 0 4px',
         WebkitBorderBottomLeftRadius: '4px',
         borderBottomLeftRadius: '4px',
         MozBorderRadiusBottomLeft: '4px',
      },
      'thead:last-child tr:last-child th:last-child,\
      tbody:last-child tr:last-child td:last-child,\
      tfoot:last-child tr:last-child td:last-child': {
         WebkitBorderBottomRightRadius: '4px',
         borderBottomRightRadius: '4px',
         MozBorderRadiusBottomRight: '4px',
      },

      // Special fixes to round the left border on the first td/th
      'caption + thead tr:first-child th:first-child,\
      caption + tbody tr:first-child td:first-child,\
      colgroup + thead tr:first-child th:first-child,\
      colgroup + tbody tr:first-child td:first-child': {
         WebkitBorderTopLeftRadius: '4px',
         borderTopLeftRadius: '4px',
         MozBorderRadiusTopLeft: '4px',
      },
      'caption + thead tr:first-child th:last-child,\
      caption + tbody tr:first-child td:last-child,\
      colgroup + thead tr:first-child th:last-child,\
      colgroup + tbody tr:first-child td:last-child': {
         WebkitBorderTopRightRadius: '4px',
         borderTopRightRadius: '4px',
         MozBorderRadiusTopRight: '4px',
      },
   },

   // Reset default grid behavior
   "table [class*='span']": {
      display: 'table-cell',
      float: 'none', // undo default grid column styles
      marginLeft: 0, // undo default grid column styles
   },

   '.table tbody tr': {
      '&.success td': {
         backgroundColor: 'tint(@green-5, 90%)',
      },
      '&.error td': {
         backgroundColor: 'tint(@red-5, 90%)',
      },
      '&.warning td': {
         backgroundColor: 'tint(@yellow-5, 90%)',
      },
      '&.info td': {
         backgroundColor: 'gray.50',
      },
   },

   tr: {
      verticalAlign: 'top',

      _notLast: {
         '& > td': {
            paddingBottom: 3,
         },
      },
   },

   'lite-youtube': {
      marginTop: 4,
      borderRadius: 'md',
      overflow: 'hidden',
      borderWidth: '1px',
      maxWidth: '100%',

      // override lite-youtube inline styles on mobile
      '@media only screen and (max-width: 575px)': {
         height: 'auto !important',
         width: '100% !important',
      },

      '&.float-left': {
         clear: 'left',
         float: 'left',
      },

      '&.float-right': {
         clear: 'right',
         float: 'right',
      },

      '&.mx-auto': {
         marginInline: 'auto',
      },
   },

   '.videoFrame': {
      maxWidth: '100%',
      marginBlock: 2,
      height: 'auto',

      '@media only screen and (max-width: 575px)': {
         width: 'auto',
      },

      '&.videoBox_center': {
         marginInline: 'auto',
      },
   },

   '.videoBox': {
      position: 'relative',
      overflow: 'hidden',
      maxWidth: '100%',
      aspectRatio: '16 / 9',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&.videoBox_center': {
         marginInline: 'auto',
      },

      '@media only screen and (min-width: 576px)': {
         '&.videoBox_left': {
            clear: 'left',
            float: 'left',
            marginRight: 6,
         },

         '&.videoBox_right': {
            clear: 'right',
            float: 'right',
            marginLeft: 6,
         },
      },

      video: {
         position: 'absolute',
         top: '0',
         right: '0',
         bottom: '0',
         left: '0',
      },
   },

   '.imageBox': {
      borderRadius: 'md',
      borderWidth: '1px',
      borderColor: 'gray.200',
      overflow: 'hidden',
      position: 'relative',
      marginBlock: { base: 4, sm: 6 },
      minWidth: 'min-content', // narrow image text-wrap fix
      width: 'fit-content',

      '& + ul, & + ol': {
         overflowX: 'auto', // clear sibling lists
         minWidth: '25%', // prevent unreadable collapsing lists
      },

      '&.imageBox_center': {
         marginInline: 'auto',

         '> img': {
            clear: 'both',
         },
      },

      img: {
         marginInline: 'auto', // center image when container is wider
      },

      '@media only screen and (max-width: 575px)': {
         width: 'fit-content',
         marginTop: { base: 4, sm: 6 },
         marginInline: 'auto',
      },

      '@media only screen and (min-width: 576px)': {
         '&.imageBox_left': {
            clear: 'left',
            float: 'left',
            marginRight: 6,
         },

         '&.imageBox_right': {
            clear: 'right',
            float: 'right',
            marginLeft: 6,
         },
      },
   },

   'table .imageBox': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      marginBlock: 0,

      img: {
         minHeight: '225px',
         objectFit: 'cover',
         width: '100%',
      },
   },

   '.imageBox p': {
      bgColor: 'gray.100',
      borderTopWidth: '1px',
      borderColor: 'gray.200',
      flex: 1,
      marginTop: 0,
      paddingBlock: 4,
      paddingInline: 3,
   },

   '.blurbListWide .grid': {
      display: 'grid',
      gap: 4,
      gridTemplateColumns: `repeat(auto-fill, minmax(${constrainStandardWidth}, 1fr) )`,

      '& .cell': {
         bgColor: 'gray.100',
         borderWidth: '1px',
         borderRadius: 'md',
         display: 'flex',
         maxWidth: constrainStandardWidth,
         overflow: 'hidden',
         _hover: {
            borderColor: 'brand.500',
            transition: 'border-color var(--chakra-transition-duration-normal)',
         },
      },

      '& a, & a:hover': {
         color: 'inherit',
         textDecoration: 'none',
      },

      '.title-text': {
         borderTopWidth: '1px',
         paddingBlock: 2,
         paddingInline: 3,
      },
   },

   '.narrowedLinkContainer': {
      display: 'contents',
   },
};
